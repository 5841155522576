.eknm-button-small {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: "Raleway";
    font-size: 1.25rem;
    border-radius: 360px;
    color: #ecf2f4;
    background-color: none;
    border: solid #ecf2f4;
    border-width: 1px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.eknm-button-small:hover {
    color: #ecf2f4;
    background-color: #33312d;
}

.eknm-button-small:active {
    color: #ecf2f4;
    background-color: #262522;
}

.eknm-button-underlined {
    justify-content: center;
    align-items: center;
    font-family: "Raleway";
    font-size: 1.25rem;
    color: #c6cbce;
}

.eknm-button-underlined:hover {
    color: #c6cbce;
    text-decoration-color: #a668d9;
}

.eknm-button-underlined:active {
    color: #c6cbce;
}

.button-inactive {
    color: gray;
    pointer-events: none;
}