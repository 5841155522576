.article-img {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    object-fit: contain;
    max-height: 40rem;
}

.article-p {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    text-align: justify;
}

.article-h3 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.article-bq {
    background-color: rgba(200, 200, 200, 0.3);
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1.5rem;
}

pre {
    background-color: rgba(200, 200, 200, 0.3);
    color: #EAD7BB;
    padding: 1rem;
}

code {
    color: #EAD7BB;
}