@import './styles-animations.css';
@import './styles-buttons.css';
@import './styles-md.css';

@font-face {
    font-family: OpenSans;
    src: url("assets/fonts/OpenSans-Variable.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: OpenSans-Bold;
    src: url("assets/fonts/OpenSans-Bold.ttf") format("truetype");
    font-style: bold;
}

@font-face {
    font-family: Raleway;
    src: url("assets/fonts/Raleway.ttf") format("truetype");
}

@font-face {
    font-family: Raleway-Black;
    src: url("assets/fonts/Raleway.ttf") format("truetype");
    font-weight: 900;
}

@font-face {
    font-family: Raleway-Mid;
    src: url("assets/fonts/Raleway.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: Raleway-Thin;
    src: url("assets/fonts/Raleway.ttf") format("truetype");
    font-weight: 300;
}

.color-white-bright {
    color: #ecf2f4;
}

.color-white {
    color: #c6cbce;
}

.color-purple-bright {
    color: #a668d9;
}

.color-purple-middle {
    color: #5a189a;
}

.color-purple-dark {
    color: #240046;
}

p {
    text-decoration: none;
    font-family: "OpenSans";
    font-weight: normal;
    position: relative;
    margin: 0;
    font-size: 1rem;
    color: #c6cbce;
}

li {
    font-family: "OpenSans";
    font-weight: normal;
    font-size: 1rem;
    color: #c6cbce;
}

h1 {
    text-decoration: none;
    font-family: "Raleway-Black";
    position: relative;
    margin: 0;
    font-size: 4rem;
    color: #ecf2f4;
    text-align: center;
}

h2 {
    text-decoration: none;
    font-family: "Raleway";
    font-weight: bold;
    position: relative;
    margin: 0;
    font-size: 1.5rem;
    color: #ecf2f4;
    text-align: justify;
    text-transform: uppercase;
}

h3 {
    font-family: 'OpenSans-Bold';
    font-size: 1.5rem;
    color: #ecf2f4;
}

.separator {
    margin: 0;
    background: #ecf2f4;
    height: 2px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.img-icon {
    max-width: 7.5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.width-fit-content {
    width: fit-content;
}

.container-standard {
    margin-right: auto;
    margin-left: auto;
    max-width: 60rem;
    padding: 0rem 2rem 0rem 2rem;
    width: 100%;
}

.full-width {
    width: 100%;
    background-color: #100f0dff;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
}

ul {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

ul li::marker {
    color: #c6cbce;
}

.blackBG {
    background-color: #100f0d;
}

@media only screen and (min-width: 700px) {
    :root {
        font-size: 16px;
    }
}

@media only screen and (max-width: 700px) {
    :root {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    :root {
        font-size: 12px;
    }
}

.gradient-down {
    height: 10rem;
    background: linear-gradient(#100f0dff, #100f0dbf);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: overlay;
}

.gradient-middle {
    background-color: #100f0dbf;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: overlay;
}

.gradient-up {
    height: 8rem;
    background: linear-gradient(#100f0dbf,#100f0dff);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: overlay;
}